.menu {
    display: flex;
}

.ict {
    margin-left: 10px;
    margin-top: 20px;
}

.icon {
    margin-left: -10px;
    margin-top: 20px;
}

.dashboardnav {
    margin-top: 20px;
}

.titlename {
    cursor: pointer;
    font-size: small;
}

// title::after {
//     float: right;
//     right: 20%;
//     content: '+';
// }

.slide {
    clear: both;
    width: 100%;
    height: 0px;
    overflow: hidden;
    transition: height 0.3s ease-in;
}

.slide div {
    color: white;
    margin: 5px 0px 5px 0px;
    padding: 10px 0px 10px 0px;
    padding-left: 40px;
    width: 100%;
    font-size: small;
}
.slide div:hover {
    background: rgb(75, 75, 75);
    border-radius: 5px;
    color: #59ee59;
    cursor: pointer !important;
}

#touch,
#stouch,
#rtouch,
#qliktouch {
    position: absolute;
    opacity: 0;
    height: 0px;
}

#touch:checked + .slide {
    color: #59ee59 !important;
    height: 200px;
}
#stouch:checked + .settingsSlide {
    color: #59ee59 !important;
    height: 100px !important ;
}

#qliktouch:checked + .qliksside {
    color: #59ee59 !important;
    height: 190px !important ;
}

#rtouch:checked + .reportSlide {
    color: #59ee59 !important;
    height: 190px !important ;
}
